/**
 * 读取modules下的api地址文件
 * 并根据文件名 生产统一的前缀
 * 例如： user.js  中的 login_account : 'login-sign'   会转化为  login_account: 'user/login-sign'
 */
const modulesFiles = require.context('./modules', true, /\.js$/)
const apiUri = modulesFiles.keys().reduce((uris, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  const vObjs = value.default
  Object.keys(vObjs).forEach(key => {
    uris[key] = moduleName + vObjs[key]
  })
  return uris
}, {})

// 这个方法应该放到 请求前置拦截中
apiUri.fill = function(url, ...params) {
  for (let i = 0; i < params.length; i++) {
    url = url.replace(/\{[^\W]*}/, '#' + i + '#')
  }
  for (let i = 0; i < params.length; i++) {
    url = url.replace('#' + i + '#', params[i])
  }
  return url
}

export default apiUri

